import { AttributeFilter, CONSUMPTION_ROLES } from '@/features/domain-ui/spots-panel/model';
import { SpotAttributeFilterInput, SpotHealth } from '@/types/iot-portal';
import { isEqual, omit } from 'lodash';
import moment from 'moment';
import { Component, Mixins } from 'vue-property-decorator';
import DeviceRoleMapMixin from './device-role-map';
import { PaginationQueryStringMixin } from './pagination-query-string';

@Component
export class SpotTableFilterMixin extends Mixins(DeviceRoleMapMixin, PaginationQueryStringMixin) {
  protected get role(): string | undefined {
    const { role = null } = this.$route.query;

    return role === null ? undefined : String(role);
  }

  protected set role(role: string | undefined) {
    if (role === this.role) {
      return;
    }

    void this.$router.replace({ query: { ...omit(this.$route.query, 'page'), role } });
  }

  protected get roles(): string[] | undefined {
    return this.role === undefined
      ? undefined
      : this.role === CONSUMPTION_ROLES
      ? Object.values(this.deviceRoleMap)
          .filter(({ consumption = false }) => consumption)
          .map(({ name }) => name)
      : [this.role];
  }

  protected get health(): SpotHealth | undefined {
    return SpotHealth[this.$route.query.health as SpotHealth] ?? undefined;
  }

  protected set health(health: SpotHealth | undefined) {
    if (health === this.health) {
      return;
    }

    void this.$router.replace({ query: { ...omit(this.$route.query, 'page'), health } });
  }

  protected get healths(): SpotHealth[] | undefined {
    return this.health === undefined ? undefined : [this.health];
  }

  protected get manufacturer(): string | undefined {
    const { manufacturer = null } = this.$route.query;

    return manufacturer === null ? undefined : String(manufacturer);
  }

  protected set manufacturer(manufacturer: string | undefined) {
    if (manufacturer === this.manufacturer) {
      return;
    }

    void this.$router.replace({ query: { ...omit(this.$route.query, 'page'), manufacturer } });
  }

  protected get manufacturers(): string[] | undefined {
    return this.manufacturer === undefined ? undefined : [this.manufacturer];
  }

  protected get attributeFilter(): AttributeFilter | undefined {
    const { attributes = null } = this.$route.query;

    return typeof attributes !== 'object' || attributes === null
      ? undefined
      : Object.fromEntries(Object.entries(attributes).map(([key, value]) => [key, String(value)]));
  }

  protected set attributeFilter(filter: AttributeFilter | undefined) {
    if (isEqual(filter, this.attributeFilter)) {
      return;
    }

    filter = Object.fromEntries(Object.entries(filter ?? {}).sort(([a], [b]) => Number(a > b) - Number(a < b)));

    void this.$router.replace({ query: { ...omit(this.$route.query, 'page'), attributes: filter as never } });
  }

  protected get attributes(): SpotAttributeFilterInput[] {
    return Object.entries(this.attributeFilter ?? {}).map(([attributeDefinitionId, value]) => ({
      attributeDefinitionId,
      values: [value ?? ''],
    }));
  }

  protected get metricsStopDate(): Date | undefined {
    const date = moment(String(this.$route.query.stop ?? ''));

    return date.isValid() ? date.toDate() : undefined;
  }

  protected set metricsStopDate(value: Date | undefined) {
    const stop = value ? moment(value).format('YYYY-MM-DD') : undefined;
    if (stop === this.$route.query.stop) {
      return;
    }

    void this.$router.replace({ query: { ...this.$route.query, stop } });
  }

  protected get normalizedMetricsStopDate(): Date | undefined {
    return moment(this.metricsStopDate).add(1, 'day').startOf('day').toDate();
  }
}
